@tailwind base;
@tailwind components;
@tailwind utilities;

/* https://coolors.co/008294-00b6c5-ffb94f-ffdb3b-32353e-e8e6e6 */

button {
  @apply rounded-md px-6 py-2 bg-primary text-primary-50;
  @apply hover:bg-primary-600;
  @apply active:bg-primary-700;
  @apply disabled:text-gray-500 disabled:bg-primary-950 disabled:border-primary-900 disabled:border-[1px];
}

select {
  @apply block py-2.5 px-0 w-full text-sm text-primary-50 bg-transparent border-0 border-b-2 border-gray-200 appearance-none;
  @apply focus:outline-none focus:ring-0 focus:border-gray-200;
}

select>option {
  @apply text-primary-50 rounded-none p-2;
  @apply disabled:bg-primary-900;
  @apply bg-slate-800 m-10;
}

button.secondary {
  @apply rounded-md px-6 py-2 bg-slate-500 hover:bg-slate-600 active:bg-slate-700 disabled:bg-slate-950 disabled:text-slate-700;
  @apply hover:bg-slate-600;
  @apply active:bg-slate-700;
  @apply disabled:bg-slate-950 disabled:text-slate-700;
}
button.success {
  @apply rounded-md px-6 py-2 bg-emerald-500 hover:bg-emerald-600 active:bg-emerald-700 disabled:bg-emerald-950 disabled:text-emerald-700;
  @apply hover:bg-emerald-600;
  @apply active:bg-emerald-700;
  @apply disabled:bg-emerald-950 disabled:text-emerald-700;
}

button.warning {
  @apply rounded-md px-6 py-2 bg-rose-500 hover:bg-rose-600 active:bg-rose-700 disabled:bg-rose-950 disabled:text-rose-700;
  @apply hover:bg-rose-600;
  @apply active:bg-rose-700;
  @apply disabled:bg-rose-950 disabled:text-rose-700;
}

a {
  @apply text-primary accent-primary-400 font-medium cursor-pointer;
  @apply hover:text-primary-300 hover:underline
}

.formInput {
  @apply relative z-0 w-full mb-5;
}

.formInput>input {
  @apply block pb-2.5 px-2 w-full text-sm text-primary-50 bg-transparent border-0 border-b-2 border-gray-300 appearance-none;
  @apply focus:outline-none focus:ring-0;
}

.formInput>label {
  @apply absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-7 scale-75 origin-[0];
  @apply peer-focus:scale-75 peer-focus:-translate-y-4 peer-focus:font-medium peer-focus:start-0 rtl:peer-focus:translate-x-1/4;
  @apply peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0;
}

.formInput>label.required, .required  {
  @apply after:content-['*'] after:text-rose-600 after:ml-2;
}

.table-hidden-cell {
  @apply hidden md:table-cell 
}

.table-cell {
  @apply p-0 pt-1 px-6 m-0 
}
.table-cell>span {
  @apply font-extrabold md:hidden
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  @apply bg-slate-600
}
::-webkit-scrollbar-track:hover {
  @apply bg-slate-700
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-slate-200 w-2
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-slate-300
}

.loader {
  width: 50px;
  aspect-ratio: 1;
  --_c: no-repeat radial-gradient(farthest-side, #008294FF 92%, #008294FF);
  background:
    var(--_c) top,
    var(--_c) left,
    var(--_c) right,
    var(--_c) bottom;
  background-size: 12px 12px;
  animation: l7 1s infinite;
}

@keyframes l7 {
  to {
    transform: rotate(.5turn)
  }
}


* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: inherit;
}